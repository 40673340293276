// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';

// let appInsights: ApplicationInsights | undefined;
// let reactPlugin: ReactPlugin | undefined;

// if (typeof window !== 'undefined') {
//   const browserHistory = createBrowserHistory();
//   reactPlugin = new ReactPlugin();
//   const connectionString = process.env.CONNECTION_STRING;
//   console.log('Connection String:', connectionString);
//   // Use the connection string from .env

//   if (!connectionString) {
//     console.error('Connection string is not defined');
//   } else {
//     appInsights = new ApplicationInsights({
//       config: {
//         connectionString,
//         extensions: [reactPlugin],
//         extensionConfig: {
//           [reactPlugin.identifier]: { history: browserHistory },
//         },
//       },
//     });
//     appInsights.loadAppInsights();
//     console.log('Application Insights initialized');

//     // Add custom properties to telemetry data
//     appInsights.addTelemetryInitializer((envelope) => {
//       const telemetryItem = envelope.data.baseData;
//       telemetryItem.properties = telemetryItem.properties || {};
//       const userEmail = envelope.tags['ai.user.accountId']; // Assuming user email is set in accountId
//       const loginTime = new Date().toISOString();
//       if (userEmail) {
//         telemetryItem.properties.userEmail = userEmail;
//       }
//       telemetryItem.properties.loginTime = loginTime;
//     });
//   }
// }

// export { reactPlugin, appInsights };

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

let appInsights: ApplicationInsights | undefined;
let reactPlugin: ReactPlugin | undefined;

if (typeof window !== 'undefined') {
  const browserHistory = createBrowserHistory();
  reactPlugin = new ReactPlugin();
  const connectionString =
    'InstrumentationKey=38734dd3-9a4d-43af-9cb7-496cbf7b24e1;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=3ce666f9-8e9e-4901-a851-42975584126f';
  appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
  console.log('Application Insights initialized');

  // Add custom properties to telemetry data
  appInsights.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.data.baseData;
    telemetryItem.properties = telemetryItem.properties || {};
    const userEmail = envelope.tags['ai.user.accountId']; // Assuming user email is set in accountId
    const loginTime = new Date().toISOString();
    if (userEmail) {
      telemetryItem.properties.userEmail = userEmail;
    }
    telemetryItem.properties.loginTime = loginTime;
  });
}

export { reactPlugin, appInsights };
