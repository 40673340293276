// import React, { useEffect } from 'react';
// import { PageBodyIntro } from '@abyss/web/ui/PageBodyIntro';
// import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { loginRequest } from '../authConfig';
// import { appInsights } from '../../configs/Login/appInsights'; // Corrected import path

// export const Login = () => {
//   const { instance, accounts } = useMsal();

//   function handleLoginRedirect() {
//     console.log('handleLoginRedirect called');
//     instance.loginRedirect(loginRequest).catch((error) => {
//       console.error('Login redirect error:', error);
//     });
//   }

//   useEffect(() => {
//     console.log('useEffect hook executed');
//     handleLoginRedirect();
//     console.log('Login redirect initiated');
//     if (appInsights) {
//       console.log('Application Insights is initialized');
//       try {
//         appInsights.trackPageView({ name: 'LoginPage' }); // Track the page view
//         console.log('Page view tracked');
//       } catch (error) {
//         console.error('Error tracking page view:', error);
//       }

//       // Set the authenticated user context
//       if (accounts.length > 0) {
//         const userId = accounts[0].username; // Use the user's email or unique identifier
//         try {
//           appInsights.setAuthenticatedUserContext(userId);
//           console.log('Authenticated user context set:', userId);

//           // Track custom event for user login
//           appInsights.trackEvent({
//             name: 'UserLogin',
//             properties: {
//               userId: userId,
//               loginTime: new Date().toISOString(),
//             },
//           });
//           console.log('User login event tracked');
//         } catch (error) {
//           console.error(
//             'Error setting authenticated user context or tracking event:',
//             error
//           );
//         }
//       } else {
//         console.warn('No authenticated accounts found');
//       }

//       // Flush telemetry data
//       try {
//         appInsights.flush();
//         console.log('Telemetry data flushed');
//       } catch (error) {
//         console.error('Error flushing telemetry data:', error);
//       }
//     } else {
//       console.error('Application Insights is not initialized');
//     }
//   }, [instance, accounts]);

//   return (
//     <React.Fragment>
//       <UnauthenticatedTemplate>
//         <PageBodyIntro>
//           <PageBodyIntro.Row>
//             <PageBodyIntro.Title>Initiating Login Process</PageBodyIntro.Title>
//           </PageBodyIntro.Row>
//         </PageBodyIntro>
//       </UnauthenticatedTemplate>
//     </React.Fragment>
//   );
// };

/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { PageBodyIntro } from '@abyss/web/ui/PageBodyIntro';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

export const Login = () => {
  const { instance } = useMsal();

  function handleLoginRedirect() {
    instance.loginRedirect(loginRequest).catch((error) => {
      return console.error(error);
    });
  }

  useEffect(() => {
    handleLoginRedirect();
  }, []);

  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <PageBodyIntro>
          <PageBodyIntro.Row>
            <PageBodyIntro.Title>Initiating Login Process</PageBodyIntro.Title>
          </PageBodyIntro.Row>
        </PageBodyIntro>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};
